import React from 'react';

// import SharingCard from '../components/sharing/SharingCard';
import ContactUs from '../components/contact/ContactUs';
import Faq from '../components/faq/Faq';
import Features from '../components/features/Features';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Hero from '../components/hero/Hero';
import SecondaryHero from '../components/hero_secondary/SecondaryHero';
import NoLimits from '../components/no_limits/NoLimits';
import Preview from '../components/preview/Preview';
import Pricing from '../components/pricing/Pricing';
import Samples from '../components/samples/Samples';

import './HomePage.css';

class Home extends React.Component {
    render() {
        return (
            <div className="container-fluid g-0">
                <Header />    
                <Hero />
                <SecondaryHero />
                
                <Samples />
                
                <Preview />
                
                <Features />
                <Pricing />
                <NoLimits />
                
                <ContactUs />
                <Faq />
                <Footer />
            </div>
        );
    }
}

export default Home;

import Sidebar from '../../components/sidebar/Sidebar';
import React from 'react';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Designer from './Designer.png';
import './Home.css'
import NewUserRow from './NewUserRow';
import ExistingUserRow from './ExistingUserRow';

const NewUserNotification = () => {
    const username = useSelector(state => (state.username));
    return (
        <div className="card">
            <h5 className="card-header">No Active Subscription</h5>
            <div className="card-body">
                <h5 className="card-title">Get started with Alchemist Bundles today!</h5>
                <p className="card-text">Hi {username}, you don't have an active subscription at the moment, head over to the billing tab and start creating bundles today.</p>
                <Link to={"/dashboard/billing"} className="btn btn-outline-primary">Get Started</Link>
            </div>
        </div>
    );
}

const UserTable = () => {
    const activeProfile = useSelector(state => (state.activeProfile));
    const username = useSelector(state => (state.username));

    let userStatus = "Active";
    let userStatusClass = "align-middle text-success"
    if (activeProfile.data.status === "inactive") {
        userStatus = "Frozen"
        userStatusClass = "align-middle text-secondary";
    }

    let count = 1;
    let parentRow = (
        <tr key={uuidv4()} >
            <th className="align-middle" scope="row">{count++}</th>
            <td className="align-middle">{username} <span className="badge bg-secondary ms-2">Admin</span></td>
            <td className="align-middle">{activeProfile.data.email}</td>
            <td className={userStatusClass}>{userStatus}</td>
            <td className="align-middle text-secondary"></td>
        </tr>
    )

    // sort accounts by earliest first?
    let childrenRows = activeProfile.data.childAccounts.map((child) => {
        return <ExistingUserRow key={uuidv4()} num={count++} child={child} />
    });

    const quantity = activeProfile.data.subscription.quantity
    while (count <= quantity) {
        childrenRows.push(<NewUserRow key={uuidv4()} num={count++} />);
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {parentRow}
                {childrenRows}
            </tbody>
        </table>

    )
}

const ExistingUser = () => {
    const activeProfile = useSelector(state => (state.activeProfile));
    const username = useSelector(state => (state.username));

    if (activeProfile === undefined || activeProfile === null) {
        return (
            <div className="container-fluid">
                <h1>Home</h1>
            </div>
        )
    }

    if (activeProfile.data.type === "child") {
        return (
            <div className="container-fluid">
                <h1>Home</h1>
                <div className="card mt-3">
                    <div className="card-body">
                        <h5 className="card-title mb-3">Download Alchemist Bundles now!</h5>
                        <p className="card-text">Hi {username}</p>
                        <p>To get started, head over to the Downloads tab to download Alchemist Bundles onto your machine.</p>
                        <Link to={"/dashboard/downloads"} className="btn btn-primary">Downloads</Link>
                    </div>
                </div>
            </div>
        )
    }

    let introStatement;

    //payment failed
    if (activeProfile.data.status === "active") {
        if (activeProfile.data.subscription.quantity === 1) {
            introStatement = (
                <>
                <p>Your subscription supports a single user. </p>
                <p>You can add more users by updating your subscription plan in the Billing tab</p>
                </>
            )
        } else {
            introStatement = (
                <>
                <p>Your subscription supports <b>{activeProfile.data.subscription.quantity}</b> users. You can invite additional users in the table below</p>
                <p>You can add more users to your subscription plan in the Billing tab</p>
                </>
            )
        }
    }
    else if (activeProfile.data.status === "inactive") {
        introStatement = (
            <>
            <p className="text-danger">Your subscription is currently frozen due to payment failure.</p>
            <p>Please head over to the <a href="/dashboard/billing">Billing</a> dashboard to update your payment card on file</p>
            </>
        )
    }
    

    return (
        <div className="container-fluid">
            <h1>Home</h1>
            {introStatement}
            <UserTable />
            {/* <br></br>
            <div className="row">
                <pre className="col-12 text-light bg-dark p-4">{JSON.stringify(activeProfile, null, 2)}</pre>
            </div> */}
        </div>
    );
}

const CancelledUser = () => {
    const username = useSelector(state => (state.username));

    return (
        <div className="card">
            <h5 className="card-header">No Active Subscription</h5>
            <div className="card-body">
                <h5 className="card-title">Restart your subscription today!</h5>
                <p className="card-text">Hi {username}, you don't have an active subscription at the moment, head over to the billing tab to get started</p>
                <Link to={"/dashboard/billing"} className="btn btn-primary">Billing</Link>
            </div>
        </div>
    );
}


const Home = (props) => {
    const activeProfile = useSelector(state => (state.activeProfile))

    let content = null;
    if (activeProfile.data === null) {
        content = <NewUserNotification />;
    }
    else if (activeProfile.data.status === "cancelled") {
        content = <CancelledUser />
    }
    else {
        content = <ExistingUser />
    }

    return (
        <div className="row sidebar_row_custom  g-0">
            <Sidebar activeIndex={props.activeIndex} />

            <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                {content}

                <div className="mt-5 text-center">
                <img src={Designer} className="img-fluid w-50 rounded-start" alt="..." />
            </div>
            </div>

            
        </div>
    );
}

export default Home;
import React from 'react';
// import Footer from '../components/footer/Footer';
// import Header from '../components/header/Header';

// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
class Loading extends React.Component {
    render() {
        return (
            // <div className="d-flex flex-column min-vh-100 ">
            //     <Header disableMenu={true} name="Dashboard" />
            //     <div className="container-xxl mt-5">
            //         <div className="d-flex justify-content-center">
            //             <div className="spinner-border" role="status">
            //                 <span className="visually-hidden">Loading...</span>
            //             </div>
            //         </div>
            //     </div>

            //     <Footer />
            // </div>
            <div className="container-fluid mt-5">
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;
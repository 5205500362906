import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import launch from './launch.png'

const Success = () => {
    const [redirecting, setRedirecting] = useState(false);

    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();

    const handleGetStarted = async () => {

        //refresh auth token
        setRedirecting(true);
        await getAccessTokenSilently({
            ignoreCache: true,
          });

        //redirect user back to dashboard page
        history.push("/dashboard");
        // setRedirecting(false);
    };

    const getStartedButton = () => {
        if (redirecting) {
            return (
                <button className="btn btn-success" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    &nbsp; Get Started
                </button>
            );
        }

        return (
            <button type="button" className="btn btn-success" onClick={handleGetStarted}>Get Started</button>
        );
    }

    return (
        <div className="row sidebar_row_custom g-0">

            <div className="col-12 contentClass p-3" >
                {/* <h1>Success</h1> */}

                {/* border-0 */}
                <div className="card"> 
                    
                    <div className="row g-0">
                        <div className="col-md-6">
                            <img src={launch} className="img-fluid rounded-start" alt="..." />
                        </div>

                        <div className="col-md-6">
                            <div className="card-body" style={{ display: "flex", flexDirection: "column", height: "100%"}}>
                                <h3 className="card-title mt-3">Subscription Successfully Created!</h3>
                                
                                <div className="card-text"style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: "white", height: "100%", display: "flex", flexDirection: "column" }} >
                                    <p className="">Thank you for purchasing Alchemist Bundles. You can now start to: </p>
                                    <ul>
                                        <li className="mb-2">Add users to your subscription</li>
                                        <li className="mb-2">Download Windows or Mac versions of Alchemist Bundles</li>
                                        <li className="mb-2">Create an unlimited number of bundles (with as many pages you need)</li>
                                        <li className="mb-2">...and much more!</li>
                                    </ul>
                                </div>

                                <div className="mb-2 mt-2" style={{ textAlign: "end" }}>
                                    {getStartedButton()}
                                </div>

                                <p className="card-text" style={{ textAlign: "end" }}><small className="text-muted">You will be redirected to your dashboard</small></p>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Success;
import React from 'react';
import './pricing.css';

const PriceCard = (props) => {

    let price;
    if (props.price === null || props.price === undefined) {
        price = (<h1 className="card-title pricing-card-title"><small className="text-muted fw-light">Get in touch</small></h1>)
    } else {
        price = (<h1 className="card-title pricing-card-title">£{props.price}<small className="text-muted fw-light">/month</small></h1>)
    }

    return (
        <div className="col text-center mx-3">
            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                    <h4 className="my-0 fw-normal">{props.title}</h4>
                </div>

                <div className="card-body">
                    {price}
                    <hr className="mt-4" />
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default PriceCard;
import React from 'react';

import './faq.css';

const faqContent = [
    {
        title: (<>Is Alchemist Bundles compliant with court regulations?</>),
        body: (<>
            Yes, Alchemist Bundles are compliant with English & Welsh courts.
            <br/><br/>
            Alchemist Bundles are highly customisable, so compliant bundles can be generated for other jurisdictions and legal domains. 
            Contact us for a free trial to learn more about the types of bundles that you can create.
        </>)
    },
    {
        title: (<>Can I purchase a subscription for my whole team or company?</>),
        body: (<>
            Yes, you can add up to 100 users to your subscription. Billing is on a per user per month basis.
            <br/><br/>
            If there are more than 3 people in your team, please contact us to receive special volume discounts.
            <br/><br/>
            After you've purchased additional licenses for your subscription, you'll be able to specify the email addresses of additional users. 
            The user(s) will then receive an email inviting them to download and start using Alchemist Bundles 
        </>)
    },
    {
        title: (<>Can I add or remove users from my subscription after I've purchased?</>),
        body: (<>
            If you need to add or remove users you can do so in our <a href="/dashboard/billing">Billing Dashboard</a>. 
            Any additional users will be pro-rated to your monthly bill. Removed users will receive a notification to explain that their access has been revoked.
        </>)
    },
    {
        title: (<>Do I need to commit to a 12 month contract?</>),
        body: (<>
            No, we offer a flexible pricing model, subscriptions work just like your Netflix subscription: pay monthly and cancel any time.
        </>)
    },
    {
        title: (<>Is there a limit to the number of bundles I can generate in a month?</>),
        body: (<>
            No, there are absolutely no limits on the number of bundles you can generate per month
            <br/><br/>
            There are also no limits on the number of pages you can have within a bundle.
        </>)
    },
    {
        title: (<>Can I modify bundles after I've generated one?</>),
        body: (<>
            Yes, whenever you need to make a change or late insert, simply re-open you case folder in Alchemist Bundles and generate a new bundle version with your modifications.
            <br/><br/>
            You can also view previously generated bundles in the Versions tab of the app.
        </>)
    },
    {
        title: (<>Are my documents secure when using Alchemist Bundles?</>),
        body: (<>
            Yes, Alchemist Bundles runs on your desktop, so your case documents never leave your machine.
        </>)
    },
    {
        title: (<>Does Alchemist Bundles work on Mac?</>),
        body: (<>
            Yes, Alchemist Bundles is compatible with MacOS and Windows. 
        </>)
    }
]

class Faq extends React.Component {

    render() {

        const items = faqContent.map((faq, index) => {
            const headingId = `heading${index}`;
            const collapseId = `collapse${index}`;

            return (
                <div key={index} className="accordion-item">
                    <h2 className="accordion-header" id={`panelsStayOpen-${headingId}`}>
                        <button className="accordion-button collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-${collapseId}`}
                            aria-expanded="false" aria-controls={`panelsStayOpen-${collapseId}`}>
                            {faq.title}
                        </button>
                    </h2>
                    <div id={`panelsStayOpen-${collapseId}`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-${headingId}`}>
                        <div className="accordion-body">
                            {faq.body}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div id="FAQs" className="container-xxl px-4 py-5">
                <h2 className="pb-2 border-bottom mb-5">Frequently Asked Questions</h2>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                 {items}
                </div>
            </div>
        )
    }
}

export default Faq;
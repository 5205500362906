
const usernameReducer = (state, { type, payload }) => {

    if (type === 'UPDATE_USERNAME') {
        return payload;
    }

    if (state === undefined) {
        return null;
    }

    return state;
}

export default usernameReducer;
import React from 'react';
import './footer.css';

class Footer extends React.Component {

    render() {
        const year = new Date().getFullYear();
        const copyright = `Alchemist Bundles. Copyright © ${year}`;

        return (
            <footer className="footer mt-auto py-3 bg-light">
                <div className="container-xxl footer-container g-4">
                    <div className="d-flex">
                        <span className="text-muted">{copyright}</span>
                    </div>

                    <div className="d-flex flex-row">
                        <a className="text-muted text-decoration-none" href="/privacy-policy">Privacy Policy</a>
                        <a className="text-muted ms-3 text-decoration-none" href="/terms-and-conditions">Terms & Conditions</a>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

// import './Sidebar.css';

const HomeItem = (props) => {

    const style = props.active === true ? "nav-link active" : "nav-link";

    return (
        <li className="nav-item">
            <Link to={"/dashboard"} className={style} aria-current="page" >
                <svg className="bi me-2" width="16" height="16">
                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                </svg>
                Home
            </Link>
        </li>
    );
}

const BillingItem = (props) => {
    const style = props.active === true ? "nav-link active" : "nav-link";


    return (
        <li className="nav-item">
            <Link to={"/dashboard/billing"} className={style} aria-current="page">
                <svg className="bi me-2" width="16" height="16">
                    <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                    <path fillRule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
                </svg>
                Billing
            </Link>
        </li>
    );
}

const ProfileItem = (props) => {
    const style = props.active === true ? "nav-link active" : "nav-link";

    return (
        <li className="nav-item">
            <Link to={"/dashboard/profile"} className={style} aria-current="page">
                <svg className="bi me-2" width="16" height="16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg>
                Profile
            </Link>
        </li>
    );
}

const DownloadsItem = (props) => {
    const style = props.active === true ? "nav-link active" : "nav-link";

    return (
        <li className="nav-item">
            <Link to={"/dashboard/downloads"} className={style} aria-current="page">
                <svg className="bi me-2" width="16" height="16">
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                </svg>
                Downloads
            </Link>
        </li>
    );
}

const TutorialsItem = (props) => {
    const style = props.active === true ? "nav-link active" : "nav-link";

    return (
        <li className="nav-item">
            <Link to={"/dashboard/tutorials"} className={style} aria-current="page">
                <svg className="bi me-2" width="16" height="16">
                <path d="M18.175,4.142H1.951C1.703,4.142,1.5,4.344,1.5,4.592v10.816c0,0.247,0.203,0.45,0.451,0.45h16.224c0.247,0,0.45-0.203,0.45-0.45V4.592C18.625,4.344,18.422,4.142,18.175,4.142 M4.655,14.957H2.401v-1.803h2.253V14.957zM4.655,12.254H2.401v-1.803h2.253V12.254z M4.655,9.549H2.401V7.747h2.253V9.549z M4.655,6.846H2.401V5.043h2.253V6.846zM14.569,14.957H5.556V5.043h9.013V14.957z M17.724,14.957h-2.253v-1.803h2.253V14.957z M17.724,12.254h-2.253v-1.803h2.253V12.254zM17.724,9.549h-2.253V7.747h2.253V9.549z M17.724,6.846h-2.253V5.043h2.253V6.846z"></path>                </svg>
                Tutorials
            </Link>
        </li>
    );
}

const Sidebar = (props) => {
    const activeProfile = useSelector(state => (state.activeProfile))

    // Default view of parent or new user
    let menu = (
        <ul className="nav nav-pills flex-column">
            {props.activeIndex === 0 ? <HomeItem active={true} /> : <HomeItem />}
            {props.activeIndex === 1 ? <BillingItem active={true} /> : <BillingItem />}
            {props.activeIndex === 2 ? <ProfileItem active={true} /> : <ProfileItem />}
            {props.activeIndex === 3 ? <DownloadsItem active={true} /> : <DownloadsItem />}
            {props.activeIndex === 4 ? <TutorialsItem active={true} /> : <TutorialsItem />}
        </ul>
    )

    if (activeProfile.data !== null && activeProfile.data.type === "child") {
        menu = (
            <ul className="nav nav-pills flex-column">
                {props.activeIndex === 0 ? <HomeItem active={true} /> : <HomeItem />}
                {props.activeIndex === 1 ? <ProfileItem active={true} /> : <ProfileItem />}
                {props.activeIndex === 2 ? <DownloadsItem active={true} /> : <DownloadsItem />}
                {props.activeIndex === 3 ? <TutorialsItem active={true} /> : <TutorialsItem />}
            </ul>
        )
    }

    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 col-xl-2 d-md-block sidebar_custom">
            <div className="position-sticky pt-3 p-2">
                {menu}
            </div>
        </nav>
    )
}

export default Sidebar;
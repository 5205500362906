import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import callUpdateUserEndpoint from '../Dashboard/api_user';
import { updateChildAccounts } from '../../redux/actions/index';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const ExistingUserRow = (props) => {
    const { child, num } = props;
    const dispatch = useDispatch();
    const activeProfile = useSelector(state => (state.activeProfile));
    const { getAccessTokenSilently } = useAuth0();

    const [sweetAlert, setSweetAlert] = useState(null);
    const [userDeleting, setUserDeleting] = useState(false);
    const [userInviting, setUserInviting] = useState(false);
    const [userReinviting, setUserReinviting] = useState(false);

    // ============== Invite User ==================
    const onInviteUser = async () => {
        setSweetAlert(null)
        const token = await getAccessTokenSilently();
        const body = {
            eventType: "invite.send",
            inviteType: "invite",
            users: [{ id: child.id, type: "child" }]
        }

        setUserInviting(true)
        try {
            await callUpdateUserEndpoint(token, body);
            toast.success(`Invitation sent to ${child.name} ✉️.`, { autoClose: 10000 })
            setUserInviting(false)
            
            const childAccounts = [...activeProfile.data.childAccounts];
            const childIndex = childAccounts.findIndex(item => item.id === child.id)
            childAccounts[childIndex].status = "active";
            dispatch(updateChildAccounts(childAccounts))
        }
        catch (error) {
            toast.error(<span>Failed to send invitation to ${child.name}. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log("Failed to invite user: " + JSON.stringify(error.message));
            setUserInviting(false)
        }
    }

    const onInviteAlert = async () => {
        setSweetAlert(
            <SweetAlert success showCancel title="Are you sure?" onConfirm={onInviteUser} onCancel={() => setSweetAlert(null)}
                confirmBtnText="Invite"    
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="light"> 
                <b>{child.name}</b> will be emailed an invitation link to login and download Alchemist Bundles.
            </SweetAlert>)
    }

    let inviteButton = userInviting ?
        <button type="button" className="btn btn-sm btn-outline-success disabled"><span className="sr-only">Inviting...</span></button> :
        <button type="button" className="btn btn-sm btn-outline-success" onClick={onInviteAlert}>Send Invite</button>;

    // ============== Delete User ==================
    const onDeleteUser = async () => {
        setSweetAlert(null)
        const token = await getAccessTokenSilently();
        const body = {
            eventType: "child_user.delete",
            users: [child.id]
        }

        setUserDeleting(true);
        try {
            await callUpdateUserEndpoint(token, body);
            toast.success(`Successfully removed ${child.name}`, { autoClose: 10000 })
            setUserDeleting(false);

            const childAccounts = [...activeProfile.data.childAccounts];
            const filteredItems = childAccounts.filter(item => item.id !== child.id)
            dispatch(updateChildAccounts(filteredItems))
        }
        catch (error) {
            toast.error(<span>Failed to remove ${child.name}. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log("Failed to Delete user: " + JSON.stringify(error.message));
            setUserDeleting(false);
        }
    }

    const onDeleteAlert = async () => {
        setSweetAlert(
            <SweetAlert warning showCancel title="Are you sure?" onConfirm={onDeleteUser} onCancel={() => setSweetAlert(null)}
                confirmBtnText="Delete"    
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"> 
                <b>{child.name}</b> will have their Alchemist Bundles access revoked and removed from your subscription.
            </SweetAlert>)
    }

    let deleteButton = userDeleting ?
        <button type="button" className="btn btn-sm btn-outline-danger ms-3 disabled"><span className="sr-only">Deleting...</span></button> :
        <button className="btn btn-sm btn-outline-danger ms-3" onClick={onDeleteAlert}>Delete</button>;

    // ============== Re-invite User ==================
    const onReinviteUser = async () => {
        setSweetAlert(null)
        const token = await getAccessTokenSilently();
        const body = {
            eventType: "invite.resend",
            inviteType: "invite",
            user: { id: child.id, type: "child" }
        }

        setUserReinviting(true);
        try {
            await callUpdateUserEndpoint(token, body);
            toast.success(`Invitation sent to ${child.name} ✉️.`, { autoClose: 10000 })
            setUserReinviting(false);
        }
        catch (error) {
            toast.error(<span>Failed to send invitation to ${child.name}. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log("Failed to re-invite user: " + JSON.stringify(error.message));
            setUserReinviting(false);
        }
    }

    const onReinviteAlert = async () => {
        setSweetAlert(
            <SweetAlert warning showCancel title="Are you sure?" onConfirm={onReinviteUser} onCancel={() => setSweetAlert(null)}
                confirmBtnText="Resend"    
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="light"> 
                An invitation email will be sent to <b>{child.name}</b>. Re-inviting will require <b>{child.name}</b> to update their account password.
            </SweetAlert>)
    }

    let reinviteButton = userReinviting ?
        <button type="button" className="btn btn-sm btn-outline-success disabled"><span className="sr-only">Inviting...</span></button> :
        <button type="button" className="btn btn-sm btn-outline-success" onClick={onReinviteAlert}>Resend Invite</button>;

    // ================================================
    let statusLabel = ""
    let action = (<></>)
    let rowStatusClass = "text-success capitalise align-middle"

    if (child.status === "active") {
        statusLabel = "Active";
        action = (<div className="d-flex "> {reinviteButton} {deleteButton} </div>)
    }
    else if (child.status === "pending") {
        statusLabel = "Pending Invite";
        rowStatusClass = "text-primary capitalise align-middle"
        action = (<div className="d-flex ">{inviteButton} {deleteButton} </div>);
    }
    else if (child.status === "inactive") {
        statusLabel = "Frozen"
        rowStatusClass = "text-secondary capitalise align-middle"
    }

    return (
        <tr>
            <th scope="row" className="align-middle">{num}{sweetAlert}</th>
            <td className="align-middle">{child.name}</td>
            <td className="align-middle">{child.email}</td>
            <td className={rowStatusClass}>{statusLabel}</td>
            <td className="text align-middle">{action}</td>
        </tr>

    )

}





export default ExistingUserRow;

const downloadsReducer = (state, { type, payload }) => {
    if (type === 'UPDATE_DOWNLOADS') {
        return payload;
    }

    if (state === undefined) {
        return null;
    }

    return state;
}

export default downloadsReducer;
import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from 'react-toastify'
import Service from './Finance.png';
import callCustomerPortalEndpoint from './api_customerportal';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const CustomerPortal = () => {
    const [redirecting, setRedirecting] = useState(false);

    const activeProfile = useSelector(state => (state.activeProfile))
    const { getAccessTokenSilently } = useAuth0();


    const callCustomerPortal = async () => {
        setRedirecting(true);
        try {
            const token = await getAccessTokenSilently();
            const responseData = await callCustomerPortalEndpoint(token);
            window.location.replace(responseData.url);
            setRedirecting(false);
        } catch (error) {
            setRedirecting(false);
            toast.error(<span>Unable to navigate to customer portal. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log(error.message);
        }
    };

    const date = new Date(activeProfile.data.subscription.created * 1000);
    const dateString = `${date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}`

    const customerPortalButton = redirecting ?
        (
            <button className="btn btn-outline-primary" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                &nbsp; Redirecting...
            </button>
        )
        : (
            <button type="button" className="btn btn-outline-primary" onClick={callCustomerPortal}>Billing Portal</button>
        );

    const nextBillingDate = new Date(activeProfile.data.subscription.current_period_end * 1000).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })
    const status = () => {
        let status = activeProfile.data.subscription.status;
        let val;
        let className;

        switch (status) {
            case 'active':
                val = "Active";
                className = "badge bg-success";
                break;
            case 'trialing':
                val = "Trialling";
                className = "badge bg-success";
                break;
            case 'past_due':
                val = "Active (Payment Failed)";
                className = "badge bg-warning";
                break;
            case 'unpaid':
                val = "Frozen (Payment Failure)";
                className = "badge bg-danger";
                break;
            case 'canceled':
                val = "Cancelled";
                className = "badge text-dark bg-light";
                break;
            default:
                val = "Unknown";
                className = "badge text-dark bg-light"
        }

        return <h4 className="text-center"><span className={className}>{val}</span></h4>;
    }

    const unitPrice = () => {
        const plan = activeProfile.data.subscription.plan;
        const amount = Number.parseFloat(plan.amount / 100).toFixed(2);
        return `£${amount} per user`;
    }


    return (
        <div>

            <div className="row row-cols-1 row-cols-md-3 g-4">
                <NextBillingDate date={nextBillingDate} subscription={activeProfile.data.subscription} />
                <div className="col">
                    <div className="card h-100">
                        <div className="card-header" style={{ fontSize: '18px' }}>Number of Users</div>
                        <div className="card-body">
                            <h3 className="text-center">{activeProfile.data.subscription.quantity}</h3>
                            <br></br>
                            <p className="card-text">You can add or remove users from your subscription. Billing will be pro-rated for the additional users.</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100">
                        <div className="card-header" style={{ fontSize: '18px' }}>Current Plan</div>
                        <div className="card-body">
                            {status()}
                            <br></br>
                            <p className="card-text"><b>Member Since:</b> {dateString}</p>
                            <b>Unit Price:</b> {unitPrice()}
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="card-body">
                <p className="card-text">The customer portal allows you to:</p>
                <ul>
                    <li>Update the quantity of users</li>
                    <li>Add additional payment methods</li>
                    <li>View invoices</li>
                    <li>Cancel your membership</li>
                </ul>
                <br />
                <p>Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> if you need assistance</p>
                <br></br>
                <div className="text-right d-flex flex-column align-items-start">
                    {customerPortalButton}
                    <p className="card-text"><small className="text-muted">You will be redirected to the Billing Portal</small> </p>
                </div>
            </div>

            <div className="mt-5 text-center">
                <img src={Service} className="img-fluid w-50 rounded-start" alt="..." />
            </div>
        </div>
    )
}

const NextBillingDate = (props) => {

    let card = (
        <>
            <div className="card-header" style={{ fontSize: '18px' }}>Next Billing Date</div>
            <div className="card-body">
                <h3 className="text-center">{props.date}</h3>
                <br></br>
                <p className="card-text">You are billed <b>monthly</b> and your subscription will continue automatically on this date. </p>
            </div>
        </>
    )

    if (props.subscription.cancel_at_period_end === true) {
        card = (
            <>
                <div className="card-header" style={{ fontSize: '18px' }}>Cancellation Date</div>
                <div className="card-body">
                    <h3 className="text-center">{props.date}</h3>
                    <br></br>
                    <p className="card-text">Your subscription is due to be cancelled on this date. You can reverse the cancellation in the Billing Portal if needed.</p>
                </div>
            </>
        )
    }

    return (
        <div className="col">
            <div className="card h-100">
                {card}
            </div>
        </div>
    )
}

export default CustomerPortal;
import React from 'react';
import './secondary-hero.css';
import world from './Location.png';

class SecondaryHero extends React.Component {
    render() {
        return (
            <div className="container-xxl px-4 py-1 border-bottom">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6 mx-auto">
                        <img src={world} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"></img>
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold lh-1 mb-3">Get ready for a <span className="post-pandemic">post-pandemic</span> world.</h1>
                        <p className="lead">Alchemist bundles is a simpler way of dealing with paperwork. In a post-pandemic world, courts prefer digital bundles. Our app helps you create bundles in seconds, saving time and money (and trees! 🌲)</p>
                        {/* <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
                            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default SecondaryHero;
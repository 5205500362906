import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Home from '../home/Home';
import Billing from '../billing/Billing';
import Profile from '../profile/Profile';
import Downloads from '../downloads/Downloads';
import Success from '../success/Success';
import { updateActiveProfile, updateUsername } from '../../redux/actions';

import Missing from './No Message.png'
import Logo from './logo_solid_512.png';
import Loading from '../Loading';
import callUpdateUserEndpoint from './api_user';
import './Dashboard.css';
import Tutorials from '../tutorials/Tutorials';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const DashboardWrapper = (props) => {
    return (
        <div className="d-flex flex-column min-vh-100 ">
            <div><ToastContainer /></div>
            <Header disableMenu={true} name="Dashboard" />
            <div className="container-xxl">
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

const Dashboard = ({ match }) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const activeProfile = useSelector(state => (state.activeProfile))

    useEffect(() => {
        async function getSubscription() {
            if (activeProfile === null) {
                let token;

                if (user.email_verified === false) {
                    token = await getAccessTokenSilently({ ignoreCache: true });
                } else {
                    token = await getAccessTokenSilently();
                }

                try {
                    async function fetchData() {
                        const response = await fetch(`${SERVER_URL}/subscription`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json"
                            }
                        });

                        if (response.status === 204) {
                            return { isValid: true, data: null };
                        }

                        const responseData = await response.json();
                        if (response.ok === false) {
                            throw new Error(responseData.message);
                        }
                        return { isValid: true, data: responseData };
                    }

                    const response = await fetchData();

                    //Use data provided name if it's available, otherwise auth0 nickname
                    let name = user.nickname;
                    if (response.isValid && response.data !== null) {
                        name = response.data.name;
                    }
                    dispatch(updateUsername(name))
                    dispatch(updateActiveProfile(response))

                } catch (err) {
                    toast.error(<span>Failed to get subscription information. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
                    console.log(`Get subscription failed: ${err.message}`);
                }
            }
        }
        getSubscription();

    });

    if (user.email_verified === false) {
        return (
            <VerifyEmail email={user.email}/>
        )
    }

    if (activeProfile === null) {
        return (<DashboardWrapper><Loading /></DashboardWrapper>)
    }

    if (!activeProfile.isValid) {
        return (
            <DashboardWrapper>
                <h5 className="mt-5">{activeProfile.message}. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> </h5>
                <div className="mt-5 text-center">
                    <img src={Missing} className="img-fluid w-50 rounded-start" alt="..." />
                </div>
            </DashboardWrapper>
        );
    }

    return (<DashboardWrapper><Content match={match} /></DashboardWrapper>);
}

const VerifyEmail = (props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [verifying, setVerifying] = useState(false);

    const onVerifyUser = async () => {
        setVerifying(true);
        const token = await getAccessTokenSilently();
        const body = { eventType: "verification.send" };

        try {
            await callUpdateUserEndpoint(token, body);
            toast.success(`Verification email sent to ${props.email} ✉️.`, { autoClose: 10000 })
        } catch (err) {
            toast.error(<span>Failed to send verification to ${props.email}. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log("Failed to send verification " + JSON.stringify(err.message));
        }
    }

    let resendVerifyStyle = "mt-3 btn btn-outline-light"
    if (verifying) {
        resendVerifyStyle = "mt-3 btn btn-outline-light disabled"
    }

    return (
        <div className="d-flex flex-column">
            <Header disableMenu={true} name="Dashboard" />
            <div><ToastContainer /></div>
            <div className="verify_email_container">
                <div className="verify_email_group parent p-4 m-3">
                    <img src={Logo} width={75} className="mb-3" alt="Alchemist Bundles Logo" />
                    <h4 className="">Please verify your email.</h4>
                    <p className="mt-3 text-center fw-light">One more step to go! We've sent an email to <br /><b>{props.email}</b>.</p>
                    <p className="mt-3 text-center fw-light">Click on the link in the email to complete the signup.
                        <br />If you don't see it, you may need to <b>check your spam folder</b>.</p>

                    <button type="button" className={resendVerifyStyle} onClick={onVerifyUser}>Resend Email</button>
                    <small className="mt-3 text-light">Need help? <a className="link-light" href={`mailto:${SUPPORT_EMAIL}`}>Contact Us</a></small>
                </div>
            </div>

        </div>
    )
}




const Content = ({ match }) => {
    const activeProfile = useSelector(state => (state.activeProfile))

    if (activeProfile.data !== null && activeProfile.data.type === "child") {
        return (
            <div className="container-xxl">
                <div className="row sidebar_row_custom">
                    <Route path={match.url} exact > <Home activeIndex={0} /> </Route>
                    <Route path={match.url + "/profile"}> <Profile activeIndex={1} /> </Route>
                    <Route path={match.url + "/downloads"}> <Downloads activeIndex={2} /> </Route>
                    <Route path={match.url + "/tutorials"}> <Tutorials activeIndex={3} /> </Route>
                </div>
            </div>
        )
    }

    return (
        <div className="mt-3 container-xxl">
            <div className="row sidebar_row_custom">
                <Route path={match.url} exact > <Home activeIndex={0} /> </Route>
                <Route path={match.url + "/billing"}> <Billing activeIndex={1} /> </Route>
                <Route path={match.url + "/profile"}> <Profile activeIndex={2} /> </Route>
                <Route path={match.url + "/downloads"}> <Downloads activeIndex={3} /> </Route>
                <Route path={match.url + "/tutorials"}> <Tutorials activeIndex={4} /> </Route>
                <Route path={match.url + "/success"}> <Success /> </Route>
            </div>
        </div>
    );
}

export default Dashboard;
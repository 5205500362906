import React from 'react'
import { InlineWidget } from "react-calendly";
import Header from '../../components/header/Header';

const Calendly = () => {
    return (
        <div >
            <Header disableMenu />
            <div>
                <InlineWidget url="https://calendly.com/alchemistbundles/demo" />
            </div>
        </div>
    )
}

export default Calendly
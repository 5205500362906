import React, { useState } from 'react';
import './contact-us.css';
import support from './Support.png';

const STATUS = {
    NOT_SENT: "not_sent",
    SENDING: "sending",
    SENT: "send",
    ERROR: "error"
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const ContactUs = (props) => {
    const [status, setStatus] = useState(STATUS.NOT_SENT);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const callSecureApi = async (e) => {
        e.preventDefault();

        setStatus(STATUS.SENDING);
        try {
            const response = await fetch(`${SERVER_URL}/contact-us`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ name, email, phone, message })
                }
            );

            const responseData = await response.json();
            if (response.ok === false) {
                throw new Error(responseData.message)
            }
            setStatus(STATUS.SENT);
            
        } catch (error) {
            console.log("Failed to call contact-us endpoint");
            setStatus(STATUS.ERROR);
        }
    };

    const getButton = () => {
        if (status === STATUS.NOT_SENT) {
            if (email === "" && phone === "") {
                return <button className="btn btn-primary btn-purple disabled btn-purple-disabled" onClick={callSecureApi}>Submit</button>
            }

            return <button className="btn btn-primary btn-purple" onClick={callSecureApi}>Submit</button>
        }

        if (status === STATUS.SENDING) {
            return (<button className="btn btn-primary btn-purple" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="sr-only">&nbsp;Submitting...</span>
            </button>)
        }

        
    }

    const getError = () => {
        if (status === STATUS.ERROR) {
            return <small className="text-danger">Sorry, something went wrong, please email us at {<a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>}</small>
        }
    }

    if (status === STATUS.SENT) {
        return <Confirmation />
    }

    let supportImage = support;
    if (props.supportImage !== undefined) {
        supportImage = props.supportImage;
    }

    return (
        <div id="Contact" className="container-xxl px-4 py-5">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6 mx-auto">
                    <img src={supportImage} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"></img>
                </div>
                <div className="col-lg-6">
                    <h1 className="display-5 fw-bold lh-1 mb-3">Contact Us</h1>
                    <p className="lead">If you'd like to know more about the product, or <a href="/demo">book a demo</a>,
                        then please leave your contact details below and we'll get back to you as soon as possible.</p>
                    <div className=" mx-auto">

                        <form className="me-4">
                            <div className="form-group mb-3">
                                <input value={name}
                                    onChange={e => setName(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Name" />
                            </div>

                            <div className="form-group mb-3">
                                <div className="row">
                                    <div className="col">
                                        <input value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email"
                                            className="form-control"
                                            placeholder="Email" />
                                    </div>
                                    <div className="col-md-auto d-flex align-items-center justify-content-center my-1">
                                        <small className="text-muted">or</small>
                                    </div>
                                    <div className="col">
                                        <input value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                            type="tel"
                                            className="form-control"
                                            placeholder="Phone Number" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <textarea value={message}
                                    onChange={e => setMessage(e.target.value)}
                                    className="form-control"
                                    rows="3"
                                    placeholder="Your message" />
                            </div>

                            {getButton()}
                        </form>
                        {getError()}

                    </div>
                </div>
            </div>
        </div>
    )

}

const Confirmation = () => {
    return (
        <div id="Contact" className="container-xxl px-4 py-5">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6">
                    <img src={support} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"></img>
                </div>
                <div className="col-lg-6">
                    <h1 className=" fw-bold lh-1 mb-3">Thanks!</h1>
                    <p className="lead">We've received your message and we'll get back to you as soon as possible!</p>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';

import Shopping from './Shopping.png';
import './Billing.css'
// import PriceCard from "../../components/pricing/PriceCard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const Basket = () => {
    const [count, setCount] = useState(1);
    const [invalidForm, setInvalidForm] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    const increment = () => {
        if (count < 1) {
            setCount(1)
        } else if (count >= 99) {
            setCount(99);
        } else {
            setCount(count + 1);
        }
        setInvalidForm(false);
    }

    const decrement = () => {
        if (count <= 1) {
            setCount(1)
        } else if (count > 99) {
            setCount(99);
        } else {
            setCount(count - 1);
        }
        setInvalidForm(false);
    }

    const onInputChange = (e) => {
        const count = parseInt(e.target.value) || 1;
        if (count < 1 || count > 99) {
            setInvalidForm(true);
        } else {
            setInvalidForm(false);
        }
        setCount(count);
    }

    const { user, getAccessTokenSilently } = useAuth0();
    const username = useSelector(state => state.username);

    const callSecureApi = async () => {
        setRedirecting(true);
        const body = {
            quantity: count,
            customerEmail: user.email,
            customerName: username
        };

        try {
            const token = await getAccessTokenSilently();
            const responseData = await callCheckoutSessionEndpoint(token, body);
            window.location.replace(responseData.url);
            setRedirecting(false);
        } catch (error) {
            setRedirecting(false);
            toast.error(<span>Unable to navigate to checkout session. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log(`Unable to navigate to checkout session: ${error.message}`);
        }
    };


    const checkout = redirecting ?
        (
            <button className="btn btn-outline-primary" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                &nbsp; Redirecting...
            </button>
        )
        : (
            <button type="button" className="btn btn-outline-primary" disabled={invalidForm} onClick={callSecureApi}>Checkout</button>
        );

    return (
        <div className="card border-0 mb-3" >
            <div className="row g-0">

                {/* <div className="col-md-6 col-xl-5 col-xxl-4 p-3">
                    <PriceCard />
                </div> */}
                <div className="col-md-6 col-xl-7 col-xxl-8">
                    <div className="card-body " style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <h5 className="card-title">Basket</h5>
                        {/* <p className="card-text">{message}</p> */}
                        <div className="" style={{ backgroundColor: "white", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                            <div className="row g-3 mt-2">
                                <div className="col-12"><span className="form-text">Please enter the number of users you'd like to add to your subscription</span></div>
                                <div className="col-auto"><label className="col-form-label">Users</label></div>
                                <div className="col-sm-4 col-6" style={{ display: "flex" }}>
                                    <button type="button" className="btn btn-outline-danger" onClick={decrement}>-</button>
                                    <input type="number" value={count} onChange={onInputChange} className="form-control col-6" min={1} max={99} placeholder={1} />
                                    <button type="button" className="btn btn-outline-success" onClick={increment}>+</button>
                                </div>
                                {renderInvalidMessage(invalidForm)}
                            </div>
                            <div className="mb-2 mt-2" style={{ textAlign: "start" }}>{checkout}</div>
                        </div>
                        <p className="card-text" style={{ textAlign: "start" }}><small className="text-muted">You will be redirected to a checkout page to complete signup</small></p>
                    </div>
                </div>

                <div className="mt-5 text-center">
                    <img src={Shopping} className="img-fluid w-50 rounded-start" alt="..." />
                </div>
            </div>
        </div>
    );
}


const renderInvalidMessage = (invalidForm) => {
    if (invalidForm) {
        return (
            <div className="text-danger">
                <small>Please enter a number between 1 and 99</small>
            </div>
        );
    }

    return (
        <div className="text-white">
            <small>&nbsp;</small>
        </div>
    );

}

const callCheckoutSessionEndpoint = async (token, body) => {
    try {
        const response = await fetch(`${SERVER_URL}/create-checkout-session`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        const responseData = await response.json();
        if (response.ok === false) {
            throw new Error(responseData.message)
        }
        return responseData;

    } catch (err) {
        console.log(`Checkout Session endpoint failed: ${err.message}`);
        throw new Error(err.message)
    }   
}

export default Basket;



export const updateChildAccounts = (payload) => {
    return {
        type: "UPDATE_CHILD_ACCOUNTS",
        payload
    }
}

export const updateActiveProfile = (payload) => {
    return {
        type: 'UPDATE_PROFILE',
        payload
    };

}

export const updateDownloadsList = (payload) => {
    return {
        type: 'UPDATE_DOWNLOADS',
        payload
    }
}

export const updateUsername = (payload) => {
    
    return {
        type: 'UPDATE_USERNAME',
        payload
    }
}
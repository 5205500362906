const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const callCustomerPortalEndpoint = async (token) => {
    try {
        const response = await fetch(`${SERVER_URL}/customer-portal`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

        const responseData = await response.json();
        if (response.ok === false) {
            throw new Error(responseData.message)
        }
        return responseData;

    } catch (err) {
        console.log("Customer Portal endpoint call failed: " + JSON.stringify(err.message));
        throw new Error(err.message)
    }

};

export default callCustomerPortalEndpoint;
import React from 'react'
import Header from '../../components/header/Header'
import failedValidation from './failed_validation.png'

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const Support = () => {
  return (
    <div >
      <Header disableMenu name="Support" />
      
      <div className="mt-5 container-xxl">
        <h1>Support</h1>
        <p>Welcome to Alchemist Bundles support pages, if you are unable to resolve your issue, please contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>support@alchemistbundles.com</a> </p>

        <hr className='mt-5'/>
        {/* Do not change this failed_validation id as it is hardcoded in the electron app */}
        <h2 id="failed_validation" className=''>File Failed Validation</h2> 
        <p>Occasionally, a file that has been dragged into the bundle pane fails validation: </p>
        <img src={failedValidation} className="d-block img-fluid border mb-3" alt="Failed Validation Example" width="700" height="500" loading="lazy"></img>
        <p>This is usually due to an issue in the way the PDF has been generated, where the file does not conform to the official Adobe PDF Schema.</p>
        <br/>
        <h4 className='text-secondary'>Print to PDF (Recommended)</h4>
        <p>A quick workaround is to re-generate the PDF using Adobe Acrobat Reader (free version is sufficient)</p>
        <ol>
          <li>Open the file in Adobe Acrobat Reader</li>
          <li>{`Choose “File” > “Print”`}</li>
          <li>Choose “Adobe PDF” from the list of printers in the print dialog box</li>
          <li>Click "Print" to use the Acrobat PDF printer</li>
          <li>Click “OK” and enter a new file name for your PDF. Save to your case folder location</li>
          <li>Drag the new file into your bundle</li>
        </ol>

        <h4 className='text-secondary mt-4'>Print & Scan</h4>
        <p>A slightly slower workaround is to physically print the file and scan it back in to your system</p>

        <h4 className='text-secondary mt-4'>Send File for Inspection</h4>
        <p>If the file does not contain any sensitive information, please can you send the file to <a href={`mailto:${SUPPORT_EMAIL}`}>support@alchemistbundles.com</a> and we can troubleshoot further. We will delete the file from our systems as soon as we have resolved the issue.</p>
        <hr/>
      </div>
    </div>
  )
}

export default Support
import React from 'react';
import { useSelector } from 'react-redux'

import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as Logo } from './Logo.svg';
import './header.css';

const AuthButton = () => {
    const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
    const username = useSelector(state => state.username);
    
    if (isAuthenticated) {
        let user_name = username;
        if (username === null) {
            user_name = user.nickname
        }
        
        return (
            <div className="text-end">

                <div className="dropdown test">
                    <a href="/" className="d-flex align-items-center link-light text-decoration-none dropdown-toggle me-4"
                        id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                        <strong>{user_name}</strong>
                    </a>

                    <ul className="dropdown-menu-right dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                        <li><a className="dropdown-item" href="/dashboard">Dashboard</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="#logout" onClick={() => logout({
                            returnTo: window.location.origin,
                        })}>Sign out</a></li>
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div className="text-end me-3">
            <button type="button" className="btn btn-outline-light me-2" onClick={() => loginWithRedirect()}>Login</button>
            <button type="button" className="btn btn-light" onClick={() => loginWithRedirect({ screen_hint: "signup" })}>Sign-up</button>
        </div>
    );
}


const Header = (props) => {
    let menuitems = (
        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li><a href="#Samples" className="nav-link px-2 link-light">Samples</a></li>
            <li><a href="#Features" className="nav-link px-2 link-light">Features</a></li>
            <li><a href="#Preview" className="nav-link px-2 link-light">Preview</a></li>
            <li><a href="#Pricing" className="nav-link px-2 link-light">Pricing</a></li>
            <li><a href="#Contact" className="nav-link px-2 link-light">Contact</a></li>
            <li><a href="#FAQs" className="nav-link px-2 link-light">FAQs</a></li>
        </ul>
    );
    if (props.disableMenu) {
        menuitems = (
            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                <li><span className="nav-link px-2 link-light">{props.name}</span></li>
            </ul>
        );
    }

    let logo;
    if (process.env.REACT_APP_STAGE !== "prod") {
        logo = (
            <div>
                <span className="ms-2 text-danger">{process.env.REACT_APP_STAGE}</span>
                <Logo className="svg ms-2"/>
            </div>
        );
    } else {
        logo = (<div> <Logo className="svg ms-2" /></div>)
    }

    return (
        <nav className="navbar navbar-dark bg-dark">
            <a className="d-flex flex-row align-items-center text-decoration-none" href="/">
                {logo}
                <div className="navbar-brand mb-0 h1 logo ms-2 d-flex flex-column " >
                    <span>Alchemist</span>
                    <span>Bundles</span>
                </div>
            </a>
            {menuitems}
            <AuthButton />
        </nav>
    )
}

export default Header;
import React from 'react';

import {
    BsReverseLayoutTextWindowReverse, BiWrench, GoLaw,
    AiOutlineThunderbolt, BsFileEarmarkText,
    IoBookmarksOutline, AiOutlineBuild, AiOutlineNodeIndex,
    ImSortNumbericDesc, VscVersions, ImMakeGroup, RiCheckboxMultipleBlankLine
} from 'react-icons/all';

import './features.css';

const featureItems = [
    {
        title: "Intuitive UI",
        content: "Drag and drop files into the editor, rename them, and re-order files as needed",
        icon: (<BsReverseLayoutTextWindowReverse size="1.75em" />)
    },
    {
        title: "Instant Bundle Preview",
        content: "Iterate on your bundle by instantly previewing the final bundle whenever you make a change",
        icon: (<AiOutlineThunderbolt size="1.75em" />)
    },
    {
        title: "Merge Files",
        content: "Simulate file merges by hiding individual files in the index so that they appear merged in the final bundle",
        icon: (<ImMakeGroup size="1.75em" />)
    },
    {
        title: "File Customisation",
        content: "Provide custom titles to files within a bundle, instant preview individual files to remind yourself of the contents",
        icon: (<BsFileEarmarkText size="1.75em" />)
    },
    {
        title: "Bookmarks & Hyperlinks",
        content: "Bundles are fully searchable, bookmarked, and hyperlinked so you can navigate to the right page easily",
        icon: (<IoBookmarksOutline size="1.75em" />)
    },
    {
        title: "Cover Page Builder",
        content: "Apply a professional cover page to your bundle. All text on the cover page template can be modified",
        icon: (<AiOutlineBuild size="1.75em" />)
    },
    {
        title: "Index Page Builder",
        content: "Choose between tabular and dotted index formats, as well as title and file number customisation",
        icon: (<AiOutlineNodeIndex size="1.75em" />)
    },
    {
        title: "Page Numbering",
        content: "Add numeric or alpha-numeric page number systems. Additional numbering systems are coming soon!",
        icon: (<ImSortNumbericDesc size="1.75em" />)
    },
    {
        title: "Bundle Versioning",
        content: "Previous bundle output files are stored and can be viewed at any time. Late inserts can be added any time",
        icon: (<VscVersions size="1.75em" />)
    },
    {
        title: "Flexible Output",
        content: "Alongside the overall bundle, individual section files are produced to navigate around email attachment size limits",
        icon: (<RiCheckboxMultipleBlankLine size="1.75em" />)
    },
    {
        title: "Section Customisation",
        content: "Sections can be configured to start on the right hand side. Section covers can also be added before each section",
        icon: (<BiWrench size="1.75em" />)
    },
    {
        title: "Court Compliant",
        content: "Compliant with English & Welsh Courts for Family, Immigration, Civil, and many other legal domains.",
        icon: (<GoLaw size="1.75em" />)
    },
]

const Features = () => {

    const items = featureItems.map( (feature, index) => {

        return (
            <div key={index} className="col d-flex align-items-start">
                <div className="bi text-muted flex-shrink-0 me-3 rebeccapurple" >
                    {feature.icon}
                </div>
                <div>
                    <h5 className="fw-bold mb-0 feature-title">{feature.title}</h5>
                    <p>{feature.content}</p>
                </div>
            </div>
        );
    });

    return (
        <div className="container-xxl px-4 py-5" id="icon-grid">
            <h2 id="Features" className="pb-2 border-bottom">Features</h2>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
                {items}
            </div>
        </div>
    );
}

export default Features;
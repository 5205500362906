import { combineReducers } from 'redux';
import profileReducer from './ProfileReducer';
import downloadsReducer from './DownloadsReducer';
import usernameReducer from './UsernameReducer';

export default combineReducers(
    {
        username: usernameReducer,
        activeProfile: profileReducer,
        downloadsList: downloadsReducer
    }
);
import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import HomePage from './views/HomePage';
import Dashboard from './views/Dashboard/Dashboard';
import ProtectedRoute from './auth/protected-route';
import Loading from './views/Loading'
import PrivacyPolicy from './views/policies/privacy-policy/PrivacyPolicy';
import TermsAndConditions from './views/policies/terms-and-conditions/TermsAndConditions';
import Calendly from './views/calendly/Calendly';
import Support from './views/support/Support'

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <Loading className="mt-5"/>
    );
  }

  if (process.env.REACT_APP_STAGE === "local" || process.env.REACT_APP_STAGE === "prod") {
    return (
      <Switch>
        <Route exact path="/"><HomePage /></Route>
        <Route exact path="/demo"><Calendly /></Route>
        <Route exact path="/support"><Support /></Route>
        <Route exact path="/privacy-policy"><PrivacyPolicy /></Route>
        <Route exact path="/terms-and-conditions"><TermsAndConditions /></Route>
        <ProtectedRoute path="/dashboard" component={Dashboard} />
      </Switch>

    );
  }

  return (
    <Switch>
      <ProtectedRoute exact path="/" component={HomePage} />
      <ProtectedRoute exact path="/support" component={Support}/>
      <ProtectedRoute exact path="/demo" component={Calendly}/>
      <ProtectedRoute exact path="/privacy-policy" component={PrivacyPolicy} />
      <ProtectedRoute exact path="/terms-and-conditions" component={TermsAndConditions} />
      <ProtectedRoute path="/dashboard" component={Dashboard} />
    </Switch>
  );
}

export default App;

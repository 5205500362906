
import React from 'react';
import { useSelector } from 'react-redux'
import Sidebar from '../../components/sidebar/Sidebar';

import './Billing.css'
import ExistingCustomer from "./ExistingCustomer";
import Basket from "./Basket";
import CustomerPortal from "./CustomerPortal";


const Billing = (props) => {
    const activeProfile = useSelector(state => (state.activeProfile))

    let view = null;
    if (activeProfile.data === null) {
        view = <Basket />;
    }
    else if (activeProfile.data.status === "cancelled") {
        view = <ExistingCustomer />;
    }
    else {
        view = <CustomerPortal />
    }

    return (
        <div className="row sidebar_row_custom g-0">
            <Sidebar activeIndex={props.activeIndex} />

            <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                <h1>Billing</h1>
                {view}
            </div>
        </div>
        
    );
}


export default Billing;
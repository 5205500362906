import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import './hero.css';
import app_screenshot from './Alchemist-Bundles.png'

const Hero = () => {
    const history = useHistory();
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="back pt-5">
            <div className="container-xxl px-4 pt-5 pb-5 mb-5 text-center border-bottom">
                <h1 className="display-4 fw-bold">Create Legal Bundles. <span className="instant">Instantly.</span></h1>

                <div className="col-lg-10 mx-auto">
                    <p className="lead mb-4">
                        Tired of spending hours manually creating court bundles? <br />
                        Alchemist Bundles helps you create clean, compliant, professional bundles.

                        <br/><br/>
                        Get started with a free trial today!
                    </p>

                    <div className=" gap-2 d-sm-flex justify-content-sm-center mb-5">
                    <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3 purple-button"
                            onClick={() => loginWithRedirect({ screen_hint: "signup" })}>Start Free Trial</button>

                        <button type="button" className="btn btn-outline-purple btn-lg px-4 me-sm-3"
                            onClick={() => history.push("/demo")}>Book a demo</button>
                    </div>

                </div>

                <div className="overflow-hidden" styles={{ maxHeight: "30vh" }}>
                    <div className="container-fluid">
                        <img src={app_screenshot} className="img-fluid shadow mb-4 rounded-img" 
                            alt="App Screenshot" width="1000" height="800" loading="lazy" />
                    </div>
                </div>

            </div>
        </div>
    );

}

export default Hero;
import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { updateDownloadsList } from '../../redux/actions';
import { toast } from 'react-toastify';
import Sidebar from '../../components/sidebar/Sidebar';

import AppleLogo from './apple_logo.png';
import WindowsLogo from './windows_logo.png';
import Coder from './Coding.png'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const Downloads = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const downloadsList = useSelector(state => (state.downloadsList))

    useEffect(() => {
        async function getDownloads() {
            if (downloadsList === null) {
                const token = await getAccessTokenSilently();
                try {
                    async function fetchData() {
                        const response = await fetch(`${SERVER_URL}/downloads`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json"
                            }
                        });
        
                        if (response.status === 204) {
                            return [];
                        }
                        const responseData = await response.json();
                        return responseData;
                    }

                    const response = await fetchData();
                    dispatch(updateDownloadsList(response))
                } catch (err) {
                    toast.error(<span>Failed to retrieve downloads. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
                    console.log(`Failed to get downloads ${err.message}`);
                }
            }
        }
        getDownloads(); 
    }, [getAccessTokenSilently, dispatch, downloadsList]);


    if (downloadsList === null) {
        return (
            <div className="row sidebar_row_custom g-0">
                <Sidebar activeIndex={props.activeIndex} />

                <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                    <h1>Downloads</h1>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }

    if (downloadsList.length === 0) {
        return (
            <div className="row sidebar_row_custom g-0">
                <Sidebar activeIndex={props.activeIndex} />

                <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                    <h1>Downloads</h1>
                    <p>No downloads available</p>
                    <div className="mt-3 text-center">
                        <img src={Coder} className="img-fluid w-50 rounded-start" alt="..." />
                    </div>
                    <div className="text-center mt-4">
                        <p><small>By downloading and using Alchemist Bundles, you agree to the license terms and privacy statement.</small></p>
                    </div>
                </div>
                
            </div>
        )
    }

    let windowsList = [];
    let macList = [];
    downloadsList.forEach(item => {
        if (item.platform === "windows") {
            windowsList.push(item);
        }

        if (item.platform === "mac") {
            macList.push(item);
        }
    });

    //sort latest first
    windowsList.sort((first, second) => { return second.creationDate - first.creationDate });
    macList.sort((first, second) => { return second.creationDate - first.creationDate });



    return (
        <div className="row sidebar_row_custom g-0">
            <Sidebar activeIndex={props.activeIndex} />

            <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                <h1>Downloads</h1>
                <div className="row mt-5" >
                    <WindowsList data={windowsList} />
                    <MacList data={macList} />
                </div>


                <div className="mt-3 text-center">
                    <img src={Coder} className="img-fluid w-50 rounded-start" alt="..." />
                </div>
                <div className="text-center mt-4">
                    <p><small>By downloading and using Alchemist Bundles, you agree to the license terms and privacy statement.</small></p>
                </div>
            </div>
        </div>
    );
}

const WindowsList = ({ data }) => {
    //render data here

    const elements = data.map((item, index) => {
        return (
            <tr key={item.fileName}>
                <td>{item.version}</td>
                <td>{item.size}</td>
                <td>{new Date(item.creationDate).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
                <td><a href={item.presignedURL} download>.exe</a></td>
            </tr>
        );
    });

    const latestVersion = `Version: ${data[0].version}`
    const latestDate = `Published: ${new Date(data[0].creationDate).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}`


    return (

        <div className="col-md-6 col-12 p-3" style={{ backgroundColor: 'white' }}>
            <div className="text-center mb-5" >
                <img src={WindowsLogo} height="120px" alt="Windows Logo" />
            </div>
            <div className="text-center mb-5" >

                <div className="btn btn-outline-primary mb-3" type="submit">
                    <a href={data[0].presignedURL} download style={{ textDecoration: "none" }}>
                        Download for Windows
                    </a>
                </div>
                <p>
                    <small>Download the latest version for Windows</small><br />
                    <small>{latestVersion}</small><br />
                    <small>{latestDate}</small>
                </p>
            </div>

            <h5 className="mb-3">Windows Versions</h5>
            <table className="table table-sm text-center">
                <thead className="table-light">
                    <tr>
                        <th>Version</th>
                        <th>Size</th>
                        <th>Released</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>{elements}</tbody>
            </table>
        </div>
    );
}

const MacList = ({ data }) => {

    //render data here
    const elements = data.map(item => {
        return (
            <tr key={item.fileName}>
                <td>{item.version}</td>
                <td>{item.size}</td>
                <td>{new Date(item.creationDate).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
                <td><a href={item.presignedURL} download>.dmg</a></td>
            </tr>
        );
    });

    const latestVersion = `Version: ${data[0].version}`
    const latestDate = `Published: ${new Date(data[0].creationDate).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}`

    return (
        <div className="col-md-6 col-12 p-3" style={{ backgroundColor: 'white' }}>
            <div className="text-center mb-5">
                <img src={AppleLogo} height="120px" alt="Apple Logo" />
            </div>
            <div className="text-center mb-5" >

                <div className="btn btn-outline-primary mb-3" type="submit">
                    <a href={data[0].presignedURL} download style={{ textDecoration: "none" }}>
                        Download for Mac OS
                    </a>
                </div>
                <p>
                    <small>Download the latest version for Mac OS</small><br />
                    <small>{latestVersion}</small><br />
                    <small>{latestDate}</small>
                </p>
            </div>

            <h5 className="mb-3">Mac OS Versions</h5>
            <table className="table table-sm text-center">
                <thead className="table-light">
                    <tr>
                        <th>Version</th>
                        <th>Size</th>
                        <th>Released</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>{elements}</tbody>
            </table>

        </div>
    );
}

export default Downloads;
import React from 'react';
import Sidebar from '../../components/sidebar/Sidebar';

const Tutorials = (props) => {
    return (
        <div className="row sidebar_row_custom g-0">
            <Sidebar activeIndex={props.activeIndex} />

            <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                <h1>Tutorials</h1>

                <h4 className='mt-5 mb-4'>1. Installing Alchemist Bundles</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/lWNUMxRT8qI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <h4 className='mt-5 mb-4'>2. Preparing your bundle</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/xTuWJxSwsrE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <h4 className='mt-5 mb-4'>3. Creating a bundle ⭐</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Lc2vCLwoF54" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <h4 className='mt-5 mb-4'>4. Formatting a bundle</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/kRoOJq8kFfY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <h4 className='mt-5 mb-4'>5. Editing an existing bundle</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/HqFUHHnQyMU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <h4 className='mt-5 mb-4'>6. Using your bundle</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/5aTU8SCIRZI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
        </div>
    )
}

export default Tutorials
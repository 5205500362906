import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import './pricing.css';
import PriceCard from './PriceCard';

const Pricing = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div id="Pricing" className="container-xxl px-4 py-4">
            <h2 className="pb-2 border-bottom">Pricing</h2>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 mb-3 justify-content-center mt-5">

                <PriceCard title="Sole Practitioner" price="45">
                    <ul className="list-unstyled mb-4">
                        <li>Single SRA registered user</li>
                        <li>&nbsp;</li>
                        <li>Perfect for sole practitioners</li>
                        <li>Email support included</li>
                    </ul>
                    <button type="button" className="w-100 btn btn-lg btn-outline-purple"
                        onClick={() => loginWithRedirect({ screen_hint: "signup" })}>Get Started</button>
                </PriceCard>

                <PriceCard title="Small Firm" price="60">
                    <ul className="list-unstyled mb-4">
                        <li>Up to 3 users</li>
                        <li>&nbsp;</li>
                        <li>Email support included</li>
                        <li>Future software updates</li>
                    </ul>
                    <button type="button" className="w-100 btn btn-lg btn-outline-purple"
                        onClick={() => loginWithRedirect({ screen_hint: "signup" })}>Get Started</button>
                </PriceCard>

                <PriceCard title="Enterprise">
                    <ul className="list-unstyled mb-4">
                        <li>Special volume discounts available</li>
                        <li>&nbsp;</li>
                        <li>Early feature access</li>
                        <li>Dedicated account manager</li>
                    </ul>
                    <button type="button" className="w-100 btn btn-lg btn-primary btn-purple"
                        onClick={() => {document.getElementById('Contact')?.scrollIntoView()}}>Contact Us</button>
                </PriceCard>


            </div>
        </div>
    );

}

export default Pricing;
import React from 'react';

import './nolimits.css';

const NoLimits = () => {
    return (
        <div className="px-4 py-0 text-center bg-light">
        <div className="container-xxl px-4 py-1">
            <h1 className="pb-2 pt-5">We don't do quotas or limits! 📈</h1>
            {/* <h1 className="display-6 fw-bold lh-1 mb-4">No Quotas or Limits! 📈</h1> */}

            <div className="flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="my-3">
                    <h1 className="display-10 fw-bold lh-1 mb-3"><span className="unlimited">Unlimited</span> Bundles.</h1>
                    <h1 className="display-10 fw-bold lh-1 mb-3"><span className="unlimited">Unlimited</span> Pages.</h1>
                    <small className="text-muted">(No, seriously...there are no limits)</small>
                </div>
            </div>
        </div>
        </div>
    );

}

export default NoLimits;
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import { updateChildAccounts } from '../../redux/actions/index';
import callCreateUserEndpoint from '../Dashboard/api_user';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;


const NewUserRow = (props) => {
    const dispatch = useDispatch();
    const activeProfile = useSelector(state => (state.activeProfile));
    const { getAccessTokenSilently } = useAuth0();

    const [sweetAlert, setSweetAlert] = useState(null);
    const [userApplying, setUserApplying] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');


    const onCreateUser = async () => {
        setSweetAlert(null)
        const token = await getAccessTokenSilently();
        const body = {
            eventType: "child_user.create",
            name: name,
            email: email
        }

        setUserApplying(true);
        try {
            const responseData = await callCreateUserEndpoint(token, body);
            toast.success(`Successfully created account for ${name}.`, { autoClose: 10000 })

            setUserApplying(false);

            const updatedChildAccounts = [ ...activeProfile.data.childAccounts ];
            updatedChildAccounts.push(responseData)
            dispatch(updateChildAccounts(updatedChildAccounts))
        }
        catch (error) {
            toast.error(<span>Failed to create account for ${name}. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log("Failed to create account: " + JSON.stringify(error.message));
            setUserApplying(false);
        }
    }

    const onCreateAlert = async () => {
        setSweetAlert(
            <SweetAlert info showCancel title="Are you sure?" onConfirm={onCreateUser} onCancel={() => setSweetAlert(null)}
                confirmBtnText="Create"    
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="light"> 
                An account for <b>{name}</b> will be created. To activate the account, please invite <b>{name}</b> to Alchemist Bundles following creation.
            </SweetAlert>)
    }

    let button = (<button className="btn btn-sm btn-outline-secondary disabled">Create User</button>)

    if (userApplying) {
        button = (<button className="btn btn-sm btn-outline-primary" type="button"><span className="sr-only">Creating...</span></button>)
    }
    else if (name !== '' && email !== '' && email.includes('@')) {
        button = (<button className="btn btn-sm btn-outline-primary" onClick={onCreateAlert}>Create User</button>)
    }

    return (
        <tr>
            <th className="align-middle" scope="row">{props.num}{sweetAlert}</th>
            <td><input type="text" className="form-control align-middle" placeholder="Name" value={name} onChange={e => setName(e.target.value)} /></td>
            <td><input type="email" className="form-control align-middle" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} /></td>
            <td className="text capitalise align-middle"></td>
            <td className="text align-middle">{button}</td>
        </tr>
    )
}

export default NewUserRow
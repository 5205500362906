import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from '../../components/sidebar/Sidebar';
import ServiceImage from './Service.png';
import { toast } from 'react-toastify';
import callUpdateUserEndpoint from '../Dashboard/api_user';
import { updateUsername } from '../../redux/actions/index';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const Profile = (props) => {
    return (
        <div className="row sidebar_row_custom g-0">
            <Sidebar activeIndex={props.activeIndex} />

            <div className="col-md-9 col-lg-10 col-xl-10 contentClass p-3" >
                <h1 className="mb-5">Profile</h1>

                <div className="container-xxl">
                    <div className="row">
                        <Account />
                        <LoginDetails />
                    </div>
                </div>

                <div className="mt-5 text-center">
                    <img src={ServiceImage} className="img-fluid w-50 rounded-start" alt="..." />
                </div>
            </div>
        </div>
    );
}

const Account = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const username = useSelector(state => (state.username));

    const [nameChanging, setNameChanging] = useState(false);
    const [name, setName] = useState(username);

    const onNameChange = async () => {
        const token = await getAccessTokenSilently();
        const body = {
            eventType: "update.name",
            name: name
        }

        setNameChanging(true);
        try {
            await callUpdateUserEndpoint(token, body);
            setNameChanging(false);
            toast.success(`Name successfully changed to ${name}`, { autoClose: 10000 }) 
            dispatch(updateUsername(name))

            await getAccessTokenSilently({ignoreCache: true}); 
        } catch (err) {
            toast.error(<span>Failed to update name. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            setNameChanging(false);
        }
    }

    let updateButton = nameChanging ? 
        <button type="button" className="btn btn-sm btn-outline-primary ms-2 disabled" onClick={onNameChange}>Updating...</button> :
        <button type="button" className="btn btn-sm btn-outline-primary ms-2" onClick={onNameChange}>Update</button> 

    return (
        <div className="col-md-6 ">
            <h4 className="mb-4">Account</h4>
            <h6>Change Name</h6>
            <div className="container g-0 m-0 d-flex flex-column" style={{ maxWidth: "400px" }}>
                <input type="text" className="form-control form-control-sm align-middle" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                <div className="d-flex flex-direction-row justify-content-end mt-2">
                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => setName(username)}>Cancel</button>
                    {updateButton}
                </div>
            </div>
        </div>
    )
}

const LoginDetails = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const onPasswordChange = async () => {
        const token = await getAccessTokenSilently();
        const body = { eventType: "password.change" }

        setIsChangingPassword(true)
        try {
            const link = await callUpdateUserEndpoint(token, body);
            setIsChangingPassword(false)
            window.location = link.ticket;
        } catch (err) {
            toast.error(<span>Failed to update password. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            setIsChangingPassword(false)
        }
    }

    let passwordChangeButton = (<button type="button" className="btn btn-sm btn-outline-primary" onClick={onPasswordChange}>Reset Password</button>)

    if (isChangingPassword) {
        passwordChangeButton = (
            <button type="button" className="btn btn-sm btn-outline-primary disabled">
                Reset Password &nbsp;
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </button>)
    }

    return (
        <div className="col-md-6">
            <h4 className="mb-4">Login Details</h4>
            <h6>Password</h6>
            {passwordChangeButton}
        </div>
    )
}



export default Profile;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import './preview.css';

import vid from './Alchemist_Bundles_Preview.mp4'
import apple from './Apple_logo_black.png'
import windows from './Microsoft_logo.png'

class Preview extends React.Component {
    render() {
        return (
            <div className="container-xxl px-4 py-5">

                <h1 className="display-5 fw-bold lh-1 mt-5">Documents don't leave your machine 🔐</h1>
                <p className="mt-3 lead">Alchemist Bundles is a <b>desktop app</b>, so you're in control:</p>
                <ul className="lead mt-1">
                    <li>No need to upload your sensitive case files to a web page</li>
                    <li>Drag and drop files from your local case folder into the app and re-order as needed</li>
                    <li>Iterate your case bundle by instantly previewing after you make a change</li>
                    <li>Easily add late inserts and view a history of previously generated bundles</li>
                </ul>

                
                <div id="Preview" className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <video autoPlay muted loop playsInline>
                        <source src={vid} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="d-flex flex-column align-items-end">
                    <small className="text-muted">Compatible with Mac OS and Windows</small>
                    <div>
                        <img src={apple} height={40} alt="Apple Logo" />
                        <img className="ms-3 mt-2" src={windows} height={40} alt="Windows Logo" />
                    </div>
                </div>

            </div>
        )
    }
}

export default Preview;
import React from 'react';

import CoverPageThumbnail from './Cover_Page_Thumbnail.png';
import IndexPageThumbnail from './Index_Page_Thumbnail.png';
import FinalBundleThumbnail from './Final_Bundle_Thumbnail.png'
import './samples.css';

const CoverPageSample = () => {
    return (
        <div className="col d-flex align-items-start">
            <div>
                <h2 className="text-white">Cover Page</h2>
                <p className="text-light-grey">Give your bundle a slick cover page. All text on the cover page can be customised and you can choose between a full page or classic cover page.</p>
                <div className="d-flex justify-content-center">
                    <img src={CoverPageThumbnail} className="mx-lg-auto img-fluid thumbnail" width={200} alt="Index Page" />
                </div>
                <a className="mt-4 btn-sm  btn btn-outline-light btn-lg px-4" href="/samples/Cover-Page-Sample.pdf" target="_blank">Full Page</a>
                <a className="ms-2 mt-4 btn-sm  btn btn-outline-light btn-lg px-4" href="/samples/Classic-Cover-Page-Sample.pdf" target="_blank">Classic</a>
            </div>
        </div>
    );
}

const IndexPageSample = () => {
    return (
        <div className="col d-flex align-items-start">
            <div>
                <h2 className="text-white">Index Page</h2>
                <p className="text-light-grey">Alchemist Bundles offers dotted and tabular index page formats. The index page title
                    and other index components can be customised. 
                </p>
                <div className="d-flex justify-content-center">
                    <img src={IndexPageThumbnail} className="d-block mx-lg-auto img-fluid thumbnail" width={200} alt="Index Page" />
                </div>
                <a className="mt-4 btn-sm  btn btn-outline-light btn-lg px-4" href="/samples/Index-Page-Dotted-Sample.pdf" target="_blank">Dotted</a>
                <a className="ms-2 mt-4 btn-sm  btn btn-outline-light btn-lg px-4" href="/samples/Index-Page-Tabular-Sample.pdf" target="_blank">Tabular</a>
            </div>
        </div>
    );
}

const FullBundleSample = () => {
    return (
        <div className="col d-flex align-items-start">
            <div>
                <h2 className="text-white">Full Bundle ✨</h2>
                <p className="text-light-grey">Bundles are <b>bookmarked</b> and <b>hyperlinked</b> for easier navigation and they can also include 
                section covers to help organise the content. Check it out!
                </p>
                <div className="d-flex justify-content-center">
                    <img src={FinalBundleThumbnail} className="d-block mx-lg-auto img-fluid thumbnail" width={200} alt="Index Page" />
                </div>
                <a className="mt-4 btn-sm btn btn-outline-light btn-lg px-4" href="/samples/Alchemist-Bundles-Sample.pdf" target="_blank">View</a>
            </div>
        </div>
    );
}

const Samples = () => {
    return (
        <div id="Samples" className="bg-purple text-secondary px-4 py-0 text-center">
            <div className="py-5">
                <h1 className="display-5 fw-bold text-white">Presenting the evidence...</h1>
                <div className="container-xxl mx-auto">
                    <p className="fs-5 mb-4 text-light-grey">Here are some sample files that are produced using Alchemist Bundles.</p>

                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                        <CoverPageSample />
                        <IndexPageSample />
                        <FullBundleSample />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Samples;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const callUpdateUserEndpoint = async (token, body) => {
    try {
        const response = await fetch(`${SERVER_URL}/user`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        const responseData = await response.json();
        if (response.ok === false) {
            throw new Error(responseData.message)
        }
        return responseData

    } catch (err) {
        console.log(`User endpoint call failed: ${err.message}`);
        throw new Error(err.message)
    }

};

// const timeout = async (ms) => {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }
// await timeout(3000);
// return "All good";

export default callUpdateUserEndpoint;
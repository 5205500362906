import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from 'react-toastify';
import callCustomerPortalEndpoint from './api_customerportal';
import Basket from './Basket';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const ExistingCustomer = () => {
    const [redirecting, setRedirecting] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const callCustomerPortal = async () => {
        setRedirecting(true);
        try {
            const token = await getAccessTokenSilently();
            const responseData = await callCustomerPortalEndpoint(token);
            window.location.replace(responseData.url);
            setRedirecting(false);
        } catch (error) {
            setRedirecting(false);
            toast.error(<span>Unable to navigate to billing portal. Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for assistance.</span>, { autoClose: false })
            console.log(`Unable to navigate to billing portal: ${error.message}`);
        }
    };

    const customerPortalButton = redirecting ?
        (<button className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp; Redirecting...
        </button>): 
        (<button type="button" className="btn btn-outline-primary" onClick={callCustomerPortal}>Billing Portal</button>);

    return (
        <>
            <Basket />
            <h3 className="mt-5">Previous Subscription</h3>
            <p className="card-text">Go to the Billing Portal to view invoices from your cancelled subscription</p>
            <div className="text-right d-flex flex-column align-items-start">
                {customerPortalButton}
                <p className="card-text"><small className="text-muted">You will be redirected to the Billing Portal</small> </p>
            </div>
        </>
    )
}

export default ExistingCustomer;

const profileReducer = (state, { type, payload }) => {
    if (type === 'UPDATE_PROFILE') {
        return payload;
    }

    if (type === 'UPDATE_CHILD_ACCOUNTS') {
        const newState = { ...state };
        newState.data.childAccounts = payload;
        return newState
    }

    if (state === undefined) {
        return null;
    }

    return state;
}

export default profileReducer;